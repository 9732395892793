export const testimonial = [
  {
    name: 'Elen Bracken',
    message: `It’s very well layout, clean. Staff is very friendly and helpful with smiles. They use good products and have very professional nail artists. Lovely environment.`
  },
  {
    name: 'Dana April',
    message: `The girls here are great! They did a great job every time. This place is beautiful and clean. I would come back more often to get my nails done. Five stars for their great services`
  },
  {
    name: 'Ashley Cartey',
    message: `Excellent service, very fun and fantastic people that work there. Love coming here monthly to treat myself & giving their gift certificates to friends & family.`
  }
]